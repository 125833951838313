<template>
    <div>
        <v-container>
          <v-card style="background-color: #f5f5f5" elevation="3">
            <div class="mt-2 mb-5 text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
              <span v-if="isNew">Nuevo</span>
              <span v-if="!isNew">Editar</span>
              periodo
            </div>

            <v-form
              ref="form"
              v-model="isValid"
              autocomplete="off"
            >
            <v-tabs v-model="tab" background-color="transparent" show-arrows>
              <v-tabs-slider color="blue"></v-tabs-slider>

              <v-tab href="#tab-period">Periodo</v-tab>
              <v-tab href="#tab-emp">Empresas</v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab" touchless>
              <v-tab-item :value="'tab-period'">
                <v-card style="background-color: #F5F5F5" flat>
                  <!-- FORMULARIO PERIODO -->
                  <v-row>
                    <v-col class="px-7 mt-4" cols="6" sm="3" md="3" lg="3" xl="2">
                        <v-text-field
                            class="mb-n7"
                            outlined
                            dense
                            text
                            v-model="period.PeriodCod"
                            label="Código"
                        ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2" cols="12" sm="9" md="6" lg="6" xl="5">
                      <v-text-field
                          class="mb-n7"
                          outlined
                          dense
                          text
                          v-model="period.PeriodNom"
                          required
                          :rules="[v => !!v || 'El nombre es obligatorio']"
                          label="Nombre *"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7 mt-2 mb-2" cols="12" sm="6" md="4" lg="4" xl="4">
                      <PeriodCatFind class="mb-n7" ref="periodCatFind" v-model="period.PeriodCat" label="Categoría"></PeriodCatFind>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="px-7" cols="12">
                        <v-radio-group v-model="period.Tipo" row>
                            <v-radio v-for="opt in tipoOptions" :key="opt.value"
                                :label="opt.label" :value="opt.value"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                  </v-row>
                  <!-- FECHAS -->
                  <v-row class="mt-2">
                    <v-col class="px-7" cols="12" sm="12" md="12" lg="12" xl="12">
                        <!-- Boton añadir fechas -->
                        <v-btn
                        :loading="loading"
                        :disabled="editNewPeriodFec"
                        @click="onBtnNuevoPeriodFec"
                        color="primary"
                        small
                        class="mb-2">
                        Añadir fechas
                        </v-btn>

                        <v-data-table
                        :headers="periodFecHeaders"
                        :items="periodFecDataRows"
                        :options.sync="periodFecOptions"
                        :footer-props="{itemsPerPageOptions: [100]}"
                        :server-items-length="periodFecTotalDataRows"
                        :loading="periodFecLoading"
                        @click:row="onClickRowEditarPeriodFec"
                        style="overflow: scroll"
                        mobile-breakpoint="0"
                        class="elevation-1 periodFecDt"
                        dense
                        >
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.FecDes="{ item }">
                                <span v-if="!item.edit">{{formatDate(item.FecDes)}}</span>
                                <div v-else class="py-3 mb-n7" style="min-width: 145px">
                                    <DateFind label="Desde" v-model="periodFecEditData.FecDes"></DateFind>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.FecHas="{ item }">
                                <span v-if="!item.edit">{{formatDate(item.FecHas)}}</span>
                                <div v-else class="py-3 mb-n7" style="min-width: 145px">
                                    <DateFind label="Hasta" v-model="periodFecEditData.FecHas"></DateFind>
                                </div>
                            </template>
                            <!-- eslint-disable-next-line vue/valid-v-slot -->
                            <template v-slot:item.Opt="{ item }">
                            <v-btn v-if="!item.edit"
                                color="error"
                                class="mr-2 my-1"
                                small
                                @click.stop="onBtnShowPeriodFecDeleteModal(item)"
                            >
                                <v-icon>
                                mdi-delete
                                </v-icon>
                            </v-btn>
                            <div v-else class="py-1" style="min-width: 198px" align ="right">
                                <v-btn
                                :loading="periodFecLoading"
                                :disabled="!isValidPeriodFec(periodFecEditData)"
                                @click.stop="onBtnEditPeriodFec(item)"
                                color="primary" class="mt-2 mb-4 mr-2" small
                                >
                                Aceptar
                                </v-btn>
                                
                                <v-btn
                                @click.stop="cancelPeriodFecEdit"
                                class="mt-2 mb-4 mr-2" small
                                >
                                Cancelar
                                </v-btn>
                            </div>
                            </template>
                            <!-- NUEVO DETALLE FORMULARIO -->
                            <template v-if="editNewPeriodFec" slot="body.append">
                            <tr class="new-row">
                                <td>
                                    <div class="py-3 mb-n7" style="min-width: 145px">
                                        <DateFind label="Desde" v-model="newPeriodFec.FecDes"></DateFind>
                                    </div>
                                </td>
                                <td>
                                    <div class="py-3 mb-n7" style="min-width: 145px">
                                        <DateFind label="Hasta" v-model="newPeriodFec.FecHas"></DateFind>
                                    </div>
                                </td>
                                <td align="right">
                                    <div class="py-1" style="min-width: 198px">
                                        <v-btn
                                        :loading="periodFecLoading"
                                        :disabled="!isValidPeriodFec(newPeriodFec)"
                                        @click="onBtnSavePeriodFec"
                                        color="primary" class="mt-2 mb-4 mr-2" small
                                        >
                                        Aceptar
                                        </v-btn>
                                        
                                        <v-btn
                                        @click="onBtnCancelPeriodFec"
                                        class="mt-2 mb-4 mr-2" small
                                        >
                                        Cancelar
                                        </v-btn>
                                    </div>
                                </td>
                            </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
              <v-tab-item :value="'tab-emp'" eager>
                <v-card style="background-color: #F5F5F5" flat>
                  <v-row class="mt-0">
                    <!-- EMPRESAS -->
                    <v-col class="px-7" cols="12" sm="9" md="8" lg="7" xl="6">
                      <EmpresasRelacionadas
                        ref="relatedEmps"
                        :empList="empDataList" 
                        :empTotal="empDataTotal" 
                        :loading="loading" 
                        @update-data="updateEmpData"
                      ></EmpresasRelacionadas>
                    </v-col>
                  </v-row>
                </v-card>
              </v-tab-item>
            </v-tabs-items>

            <!-- ACEPTAR / CANCELAR / BORRAR -->
            <v-row class="mt-2" justify="center">
                <v-btn
                    :loading="loading"
                    :disabled="!isValid"
                    @click="onBtnSave"
                    color="primary"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                  Aceptar
                </v-btn>
                
                <v-btn
                    to="/periodos"
                    class="mt-2 mb-4 mr-2"
                    style="width:100px"
                >
                  Cancelar
                </v-btn>

                <v-btn v-if="!isNew"
                color="error"
                class="mt-2 mb-4"
                style="width:100px"
                @click="onBtnShowDeleteModal()">
                  Borrar
                </v-btn>
            </v-row>
            </v-form>
          </v-card>
        </v-container>

        <!-- Modales de borrado -->
        <!-- Borrar periodo -->
        <v-dialog
          v-model="showDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR PERIODO
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar el periodo? Esta acción no se podrá deshacer.
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :loading="loading"
                color="primary"
                @click="onBtnDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- Borrar fechas -->
        <v-dialog
          v-model="showPeriodFecDeleteDialog"
          transition="dialog-bottom-transition"
          persistent
          width="500"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              BORRAR FECHAS
            </v-card-title>

            <v-card-text class="mt-2">
              ¿Desea borrar las fechas?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                @click="onBtnPeriodFecDelete"
              >
                Aceptar
              </v-btn>
              <v-btn
                @click="onBtnCancelPeriodFecDelete"
              >
                Cancelar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import DateFind from "../components/DateFind.vue";
import PeriodCatFind from "../components/PeriodCatFind.vue";
import EmpresasRelacionadas from "../components/EmpresasRelacionadas.vue";
export default ({
    components: {
        DateFind, PeriodCatFind, EmpresasRelacionadas
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    data () {
        return {
            tab: null,
            loading: false,
            isNew: true,
            isValid: false,
            showDeleteDialog: false,
            showPeriodFecDeleteDialog: false,
            period: {
                PeriodId: 0,
                PeriodNom: '',
                PeriodCatId: null,
                PeriodCat: null,
                Tipo: null
            },
            tipoOptions: [
                { label: 'TIENDA', value: 0 },
                { label: 'OFICINA', value: 1 }
            ],
            // Datos tabla empresas
            empDataTotal: 0,
            empDataList: [],
            // Datatable detalle
            periodFecLoading: false,
            periodFecTotalDataRows: 0,
            periodFecDataRows: [],
            periodFecOptions: {itemsPerPage: 100},
            periodFecHeaders: [
                { text: 'Desde', width:105, sortable: false, value: 'FecDes' },
                { text: 'Hasta', width:105, sortable: false, value: 'FecHas' },
                { text: '', width:100, sortable: false, value: 'Opt', align: 'end' }
            ],
            // Edición detalle
            editNewPeriodFec: false, // Indica si se esta editando las nuevas fechas
            newPeriodFec: {}, // Datos de las nuevas fechas
            periodFecEditData: null, // Datos de las fechas a editar
            selectedPeriodFec: null // Fechas seleccionadas para borrar
        }
    },
    // watch: {
    //   period: {
    //     handler () {
    //         this.validate();
    //     },
    //     deep: true,
    //   }
    // },
    mounted() {
      this.isNew = this.$route.meta.isNew;
      
      if(!this.isNew) {
        this.period.PeriodId = this.$route.params.id;
        this.loadData();
      } else {
        // Usar eager en la v-tab-item para poder tener la ref cargada
        this.$refs.relatedEmps.loadDefaultEmps();
      }
    },
    methods: {
      goToList() {
        this.$router.push('/periodos');
      },

      //#region Llamadas a la API
      getDataFromApi() {
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_PERIOD',
            PeriodId: this.period.PeriodId,
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/period", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data.Period;
            const empList = result.data.PeriodEmp;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item,
                    empList
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al cargar el periodo");
                this.goToList();
            }
          });
        })
      },
      postDataToApi() {
        this.loading = true;

        // Asignamos los IDs de los objetos hijos
        this.period.PeriodCatId = this.period.PeriodCat != null ? this.period.PeriodCat.PeriodCatId : null;
        const empIds = this.empDataList.map(m => m.EmpId);
        const periodFecs = this.periodFecDataRows.map(m => {
            return {
                PeriodFecId: m.PeriodFecId,
                FecDes: m.FecDes,
                FecHas: m.FecHas
            }
        });

        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'SAVE_ENTITY',
            Period: this.period,
            EmpIds: empIds,
            PeriodFecs: periodFecs
          };
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/period", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al guardar el periodo");
            }
          });
        })
      },
      deleteDataFromApi(){
        this.loading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'DEL_ENTITY',
            PeriodId: this.period.PeriodId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/period", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const item = result.data;

            setTimeout(() => {
                this.loading = false;
                resolve({
                    item
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.loading = false;
                alert("Error al borrar el periodo");
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      loadData() {
        this.getDataFromApi()
          .then(data => {
            if(data.item == null) {
              alert("Periodo no encontrado");
              this.goToList();
              return;  
            }

            this.period = data.item;
            
            this.empDataTotal = data.empList.length;
            this.empDataList = [];
            data.empList.forEach(i => {
              this.empDataList.push({
                EmpId: i.Emp.EmpId,
                EmpNom: i.Emp.EmpNom
              });
            });

            this.periodFecTotalDataRows = data.item.PeriodFec.length;
            this.periodFecDataRows = data.item.PeriodFec;
          });
      },
      //#endregion

      //#region  Añadir fechas
      onBtnNuevoPeriodFec() {
        this.editNewPeriodFec = true;
        this.newPeriodFec = { 
            FecDes: null, 
            FecHas: null, 
            edit: false 
        };
      },
      //#endregion

      //#region Click guardado
      onBtnSave() {
          this.validate();
          if(!this.isValid) { return; }

          this.postDataToApi().then(data => {
            if(data.item.PeriodId != null) {
                this.goToList();
            }
            else {
              alert("Error al guardar el periodo.");
            }
          });
      },
      onBtnSavePeriodFec() {
        if(!this.isValidPeriodFec(this.newPeriodFec)) { return; }
        
        this.periodFecDataRows.push(this.newPeriodFec);
        this.periodFecTotalDataRows += 1;

        this.newPeriodFec = {};
        this.editNewPeriodFec = false;
      },
      //#endregion

      //#region Editar fechas
      cancelPeriodFecEdit(){
        const editPeriodFecs = this.periodFecDataRows.filter(f => f.edit);
        editPeriodFecs.forEach(periodFec => {
          periodFec.edit = false;
        });
        this.periodFecEditData = null;
        this.$forceUpdate();
      },
      onClickRowEditarPeriodFec(item){
        if(this.loading) { return; }
        if(item.edit) { return; }
        this.cancelPeriodFecEdit();
        this.periodFecEditData = {
          FecDes: this.formatDateForInput(item.FecDes),
          FecHas: this.formatDateForInput(item.FecHas)
        };

        item.edit = true;
        this.$forceUpdate();
      },
      onBtnEditPeriodFec(dtData){
        if(!this.isValidPeriodFec(this.periodFecEditData)) { return; }
        if(dtData != null) {
          dtData.FecDes = this.periodFecEditData.FecDes;
          dtData.FecHas = this.periodFecEditData.FecHas;

          this.cancelPeriodFecEdit();
        }
      },
      //#endregion

      //#region Click borrar
      onBtnDelete(){
        this.deleteDataFromApi().then(data => {
          if (data.item.Success) {
            this.showDeleteDialog = false;
            this.goToList();
          }
          else {
            alert(data.item.Message);
          }
        });
      },
      //#endregion

      //#region  Cancelar creación de fechas
      onBtnCancelPeriodFec() {
        this.editNewPeriodFec = false;
        this.newPeriodFec = {};
      },
      //#endregion

      //#region  Eliminar detalle
      removePeriodFec(item){
        var index = this.periodFecDataRows.indexOf(item);
        if (index != -1){
          this.periodFecDataRows.splice(index, 1);
          this.periodFecTotalDataRows -= 1;
        }
      },
      onBtnPeriodFecDelete(){
        this.removePeriodFec(this.selectedPeriodFec);
        this.selectedPeriodFec = null;
        this.showPeriodFecDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowDeleteModal(){
        this.showDeleteDialog = true;
      },
      onBtnShowPeriodFecDeleteModal(item){
        this.selectedPeriodFec = item;
        this.showPeriodFecDeleteDialog = true;
      },
      onBtnCancelDelete() {
        this.showDeleteDialog = false;
      },
      onBtnCancelPeriodFecDelete() {
        this.selectedPeriodFec = null;
        this.showPeriodFecDeleteDialog = false;
      },
      //#endregion

      //#region Evento actualización empresas
      updateEmpData(data) {
        this.empDataList = data.list;
        this.empDataTotal = data.total;
      },
      //#endregion

      //#region Validar formulario
      validate() {
          this.$refs.form.validate();
      },
      isValidPeriodFec(periodFec) {
        if(periodFec.FecDes == null || periodFec.FecDes == '') { return false; }
        if(periodFec.FecHas == null || periodFec.FecHas == '') { return false; }
        return true;
      },
      //#endregion

      formatDate(value) {
        const date = new Date(value);
        return String(date.getDate()).padStart(2, "0") + '/' + String(date.getMonth() + 1).padStart(2, "0")  + '/' + date.getFullYear();
      },
      formatDateForInput(value){
        const date = new Date(value);
        return date.getFullYear() + '-' + String(date.getMonth() + 1).padStart(2, "0")  + '-' + String(date.getDate()).padStart(2, "0");
      }
    } 
})
</script>
