<template>
    <div class="px-1 mt-1">
        <!-- Boton añadir empresa -->
        <v-btn
            :loading="loading || empLoading"
            :disabled="editNewEmp"
            @click="onBtnNuevaEmpresa"
            color="primary"
            small
            class="mb-2">
            Añadir empresa
        </v-btn>

        <v-data-table
            :headers="empHeaders"
            :items="empList"
            :options.sync="empOptions"
            :footer-props="{itemsPerPageOptions: [100]}"
            :server-items-length="empTotal"
            :loading="empLoading"
            style="overflow: scroll"
            mobile-breakpoint="0"
            class="elevation-1"
            dense>
            <!-- eslint-disable-next-line vue/valid-v-slot -->
            <template v-slot:item.Opt="{ item }">
                <v-btn
                color="error"
                class="mr-2 my-1"
                small
                @click.stop="onBtnShowEmpDeleteModal(item)">
                    <v-icon>mdi-delete</v-icon>
                </v-btn>
            </template>
            <!-- NUEVA EMPRESA FORMULARIO -->
            <template v-if="editNewEmp" slot="body.append">
                <tr class="new-row">
                    <td>
                        <EmpFind class="mb-n7" ref="empCtrl" v-model="newEmp" :empToHide="empList" label="Empresa"></EmpFind>
                    </td>
                    <td style="min-width: 250px" align ="right">
                        <v-btn
                            :loading="empLoading"
                            :disabled="newEmp == null"
                            @click="onBtnSaveEmp"
                            color="primary"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Aceptar
                        </v-btn>
                        
                        <v-btn
                            @click="onBtnCancelEmp"
                            class="mt-2 mb-4 mr-2"
                            small>
                            Cancelar
                        </v-btn>
                    </td>
                </tr>
            </template>
        </v-data-table>
        
        <!-- Borrar empresa -->
        <v-dialog
            v-model="showEmpDeleteDialog"
            transition="dialog-bottom-transition"
            persistent
            width="500">
            <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                    BORRAR EMPRESA
                </v-card-title>

                <v-card-text class="mt-2">
                    ¿Desea borrar la empresa?
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        :loading="empLoading"
                        color="primary"
                        @click="onBtnEmpDelete">
                        Aceptar
                    </v-btn>
                    <v-btn @click="onBtnCancelEmpDelete">
                        Cancelar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import EmpFind from "./EmpFind.vue";
export default ({
    components: {
        EmpFind
    },
    computed: {
        ...mapState(['empId', 'perId', 'urlRaiz'])
    },
    props: ['empList', 'empTotal', 'loading'],
    data () {
        return {
            showEmpDeleteDialog: false,
            empLoading: false,
            empOptions: {itemsPerPage: 100},
            empHeaders: [
                { text: 'Empresas disponible', width: 325, sortable: false, value: 'EmpNom' },
                { text: '', width: 100, sortable: false, value: 'Opt', align: 'end' }
            ],
            editNewEmp: false, // Indica si se esta editando la nueva empresa
            newEmp: {},
            selectedEmp: null // Indica la empresa seleccionada para el borrado
        }
    },
    methods: {
      updateProps(newEmpList, newEmpTotal) {
        const data = {
          list: newEmpList,
          total: newEmpTotal
        };

        this.$emit('update-data', data);
      },

      //#region Llamadas a la API
      getDefaultEmpsFromApi() {
        this.empLoading = true;
        return new Promise((resolve) => {
          const controllerParameters = {
            Action: 'GET_DEFAULT_EMP_LIST',
            EmpId: this.empId
          };       
          const AuthToken = localStorage.getItem('token');
          axios({ method: "POST", "url": this.urlRaiz + "/api/emp", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } })
          .then(result => {
            const items = result.data;

            setTimeout(() => {
                this.empLoading = false;
                resolve({
                  items
                })
            }, 1000)
          })
          .catch(error => {
            if(error != null) {
                this.empLoading = false;
                alert('Error al obtener las empresas por defecto.');
            }
          });
        })
      },
      //#endregion

      //#region Carga de datos
      // Si se va a usar dentro de una tab añadir "eager" a la v-tab-item para asegurarse de que el componente esta cargado
      loadDefaultEmps () {
        this.getDefaultEmpsFromApi()
          .then(data => {
            const newEmpTotal = data.items.length;
            let newEmpList = [];
            data.items.forEach(i => {
              newEmpList.push({
                EmpId: i.EmpId,
                EmpNom: i.EmpNom
              });
            });
            this.updateProps(newEmpList, newEmpTotal);
          });
      },
      //#endregion

      //#region Añadir empresas
      onBtnNuevaEmpresa() {
        this.editNewEmp = true;
        this.newEmp = null;
      },
      //#endregion

      //#region Click guardado
      onBtnSaveEmp() {
        if(this.newEmp == null) { return; }

        let newEmpTotal = this.empTotal;
        let newEmpList = this.empList;

        newEmpList.push(this.newEmp);
        newEmpTotal += 1;
        this.newEmp = null,
        this.editNewEmp = false;
        
        this.updateProps(newEmpList, newEmpTotal);
      },
      //#endregion

      //#region Cancelar creación de empresa
      onBtnCancelEmp() {
        this.editNewEmp = false;
      },
      //#endregion

      //#region Eliminar empresas
      removeEmp(item) {
        let newEmpTotal = this.empTotal;
        let newEmpList = this.empList;

        var index = this.empList.map(m => m.EmpId).indexOf(item.EmpId);
        if (index != -1) {
          newEmpList.splice(index, 1);
          newEmpTotal -= 1;
          this.updateProps(newEmpList, newEmpTotal);
          this.$emit('emp-deleted', item);
        }
      },
      onBtnEmpDelete() {
        this.removeEmp(this.selectedEmp);
        this.selectedEmp = null;
        this.showEmpDeleteDialog = false;
      },
      //#endregion

      //#region Gestión de modales
      onBtnShowEmpDeleteModal(item) {
        this.selectedEmp = item;
        this.showEmpDeleteDialog = true;
      },
      onBtnCancelEmpDelete() {
        this.selectedEmp = null;
        this.showEmpDeleteDialog = false;
      }
      //#endregion
    } 
})
</script>
